var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6","sm":"12","xs":"12"}},[_c('span',{staticClass:"font-weight-black ml-1",staticStyle:{"font-size":"20px"},style:(_vm.isDark ? '' : 'color: rgb(43, 42, 42)')},[_vm._v(" "+_vm._s(_vm.$t('Marketing Calendar'))+" ")])])],1),_c('edit-campaign-dialog',{attrs:{"value_isForUpdate":true,"value_ID_for_editing":_vm.campaignId},model:{value:(_vm.showEditCampaignModal),callback:function ($$v) {_vm.showEditCampaignModal=$$v},expression:"showEditCampaignModal"}}),_c('v-row',{staticClass:"mt-3",style:(_vm.$vuetify.breakpoint.lgAndUp ? 'flex-wrap: nowrap' : '')},[_c('v-col',{attrs:{"cols":"12","lg":"8","xl":"9"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"text-capitalize cmd-button",attrs:{"depressed":"","outlined":""},on:{"click":_vm.campaignSettings}},[_c('v-icon',{attrs:{"dense":"","color":"black"}},[_vm._v(" "+_vm._s(_vm.icons.mdiTune)+" ")]),_vm._v(" "+_vm._s(_vm.$t('Settings'))+" ")],1)],1),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-spacer'):_vm._e(),_c('v-col',{attrs:{"xs":"auto","align":"end"}},[_c('v-btn-toggle',{model:{value:(_vm.toggle_exclusive),callback:function ($$v) {_vm.toggle_exclusive=$$v},expression:"toggle_exclusive"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"outlined":""}},[_c('v-icon',_vm._g(_vm._b({attrs:{"dense":"","color":_vm.toggle_exclusive === 0 ? 'primary' : 'black',"dark":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiCalendarMonthOutline)+" ")])],1)]}}])},[_c('span',[_vm._v("Calendar View")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"outlined":""}},[_c('v-icon',_vm._g(_vm._b({attrs:{"dense":"","color":_vm.toggle_exclusive === 1 ? 'primary' : 'black',"dark":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiFormatListText)+" ")])],1)]}}])},[_c('span',[_vm._v("List View")])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":_vm.icons.mdiMagnify,"label":"Search campaign","hide-details":"","dense":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),(_vm.$vuetify.breakpoint.mdAndDown)?_c('post-content-list',{staticClass:"px-0 pt-8 pb-2"}):_vm._e(),(_vm.toggle_exclusive === 0)?_c('calendar'):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(_vm.toggle_exclusive === 1)?_c('v-data-table',{staticClass:"mt-10",attrs:{"headers":_vm.tableHeaders,"items":_vm.schedules,"item-key":"id","search":_vm.search,"items-per-page":10,"loading":false,"loading-text":"Loading... Please wait","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"footer-props":{
            'items-per-page-text': _vm.$t('rowsPerPageLabel'),
            'items-per-page-options': [5, 10, 30, 50, 100],
          }},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.schedules.findIndex(function (c) { return c.id === item.id; }) + 1)+" ")]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-btn',{staticClass:"action-btn me-4",style:(_vm.isDark ? {'border-color': '#5E5669AD'} : {'border-color': 'lightgray'}),attrs:{"outlined":"","color":"#1F979E"},on:{"click":function () {
                    _vm.editSchedule(item.id)
                  }}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiSquareEditOutline))])],1),(item.statusSchedule === 'Published')?_c('v-btn',{staticClass:"action-btn me-4",style:(_vm.isDark ? {'border-color': '#5E5669AD'} : {'border-color': 'lightgray'}),attrs:{"outlined":"","color":"#1F979E"},on:{"click":function($event){return _vm.toStatistic(item)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPoll))])],1):_vm._e(),(item.statusSchedule === 'Published')?_c('v-btn',{staticClass:"action-btn",style:(_vm.isDark ? {'border-color': '#5E5669AD'} : {'border-color': 'lightgray'}),attrs:{"outlined":"","color":"#1F979E"},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPause))])],1):_vm._e(),(item.statusSchedule === 'Pending')?_c('v-btn',{staticClass:"action-btn",style:(_vm.isDark ? {'border-color': '#5E5669AD'} : {'border-color': 'lightgray'}),attrs:{"outlined":"","color":"#1F979E"},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlayOutline))])],1):_vm._e()],1)],1):_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":true,"close-on-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsHorizontalCircle))])],1)]}}],null,true)},[_c('v-list',{attrs:{"outlined":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function () {
                    _vm.editSchedule(item.id)
                  }}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiSquareEditOutline))])],1),(item.statusSchedule === 'Published')?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.toStatistic(item)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPoll))])],1):_vm._e(),(item.statusSchedule === 'Published')?_c('v-btn',{staticClass:"action-btn",style:(_vm.isDark ? {'border-color': '#5E5669AD'} : {'border-color': 'lightgray'}),attrs:{"outlined":"","color":"#1F979E"},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPause))])],1):_vm._e(),(item.statusSchedule === 'Pending')?_c('v-btn',{staticClass:"action-btn",style:(_vm.isDark ? {'border-color': '#5E5669AD'} : {'border-color': 'lightgray'}),attrs:{"outlined":"","color":"#1F979E"},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlayOutline))])],1):_vm._e()],1)],1)]}},{key:"item.statusSchedule",fn:function(ref){
                  var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center","align":"center"}},[(item.statusSchedule === 'Published')?_c('v-col',{attrs:{"align-self":"start"}},[_c('p',{staticStyle:{"color":"green"}},[_vm._v(" "+_vm._s(item.statusSchedule)+" ")])]):_vm._e(),(item.statusSchedule === 'Pending')?_c('v-col',{attrs:{"align-self":"start"}},[_c('p',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(item.statusSchedule)+" ")])]):_vm._e()],1)]}}],null,false,423437863)}):_vm._e()],1)],1),_c('v-spacer'),(_vm.$vuetify.breakpoint.lgAndUp)?_c('post-content-list'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }