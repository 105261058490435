<template>
  <div>
    <!-- Start button -->
    <v-row class="mb-2">
      <!-- Start button month -->
      <v-col
        cols="12"
        sm="8"
        justify="center"
        align="center"
      >
        <div
          class="d-flex mt-6"
          :style="`justify-content: ${$vuetify.breakpoint.xs ? 'center' : ''}`"
        >
          <v-btn
            depressed
            x-small
            class="ma-2"
            @click="$refs.calendar.prev()"
          >
            <v-icon
              dense
              color="black"
            >
              {{ icons.mdiChevronLeft }}
            </v-icon>
          </v-btn>
          <p
            v-if="$refs.calendar"
            class="font-weight-black black--text mt-3 text-subtitile-2"
          >
            {{ $refs.calendar.title }}
          </p>
          <!-- <v-spacer></v-spacer> -->
          <v-btn
            depressed
            x-small
            class="ma-2"
            @click="$refs.calendar.next()"
          >
            <v-icon
              dense
              color="black"
            >
              {{ icons.mdiChevronRight }}
            </v-icon>
          </v-btn>
        </div>
      </v-col>
      <!-- End button month -->

      <!-- Start button type -->
      <v-col
        cols="12"
        sm="4"
      >
        <v-select
          v-model="type"
          :items="types"
          dense
          outlined
          hide-details
          class="text-capitalize"
          :class="$vuetify.breakpoint.xs ? '' : 'mt-6'"
          label="type"
        ></v-select>
      </v-col>
      <!-- End button type -->
    </v-row>
    <!-- End button -->
    <v-sheet height="600">
      <v-calendar
        ref="calendar"
        v-model="value"
        color="primary"
        :weekdays="weekday"
        :type="type"
        :events="events"
        :event-overlap-mode="mode"
        :event-overlap-threshold="30"
        :event-color="getEventColor"
        @click:more="viewDay"
        @click:date="viewDay"
      >
        <template #day-label="{ date, day, present }">
          <v-btn
            fab
            small
            :color="hasEvent(date) ? 'info' : present ? 'primary' : ''"
            :disabled="false"
            class="mb-1"
            @click="viewDay({ date })"
          >
            <span v-text="day" />
          </v-btn>
        </template>

        <!-- To mark current time in display -->
        <template v-slot:day-body="{ date, week, event }">
          <div
            class="v-current-time"
            :class="{ first: date === week[0].date }"
            :style="{ top: nowY }"
          >
            {{ event }}
          </div>
        </template>

        <!-- Display post banner in calendar -->
        <template
          v-slot:event="{ event }"
        >
          <div
            v-if="type !== 'month'"
            class="mini-banner-post"
            :style="`margin-left: ${event.marginLeft}px`"
          >
            <span>{{ event.time.substr(0, 5) }}</span>
            <v-img
              :lazy-src="event.banner"
              height="75"
              width="75"
              :src="event.banner"
            ></v-img>
          </div>

          <div
            v-if="type ==='month'"
            style="background: var(--v-info-base)"
          >
            <span class="font-weight-bold">{{ event.time.substr(0, 5) }}</span>
            <span> {{ event.name.length > 13 ? `${event.name.substr(0, 14)}...`: event.name }}</span>
          </div>
        </template>
      </v-calendar>
    </v-sheet>
  </div>
</template>

<script>
/* eslint-disable vue/prop-name-casing */
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js'

export default {
  name: 'Calendar',
  setup() {
    return {
      icons: { mdiChevronLeft, mdiChevronRight },
    }
  },
  data() {
    return {
      type: 'week',
      types: ['month', 'week', 'day', '4day'],
      mode: 'stack',
      modes: ['stack', 'column'],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
        { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
        { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
        { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
      ],
      value: '',
      colors: ['primary', 'warning', 'error', 'info', 'secondary'],
      ready: false,
    }
  },
  computed: {
    events() {
      const schedules = this.$store.getters['marketingCalendar/getSchedules']

      const events = schedules
        ?.sort((a, b) => new Date(`${a.date}T${a.time}`) - new Date(`${b.date}T${b.time}`))
        .reduce((result, post, index) => {
          const start = new Date(`${post.date}T${post.time}`)
          const prevEvent = result[result.length - 1]

          result.push({
            name: post.post_name,
            start,
            color: '',
            timed: true,
            time_to_post: post.time_to_post,
            date: post.date,
            time: post.time,
            description: post.description,
            idCampaign: post.idCampaign,
            banner: post.message_template.attachments?.[0]?.url,
            marginLeft: index === 0 ? 0 : this.calcMargin(start, prevEvent),
          })

          return result
        }, [])

      return events
    },
    cal() {
      return this.ready ? this.$refs.calendar : null
    },
    nowY() {
      return this.cal ? `${this.cal.timeToY(this.cal.times.now)}px` : '-10px'
    },
  },
  mounted() {
    this.ready = true
    this.scrollToTime()
    this.updateTime()
  },
  methods: {
    getEventColor(event) {
      return event.color
    },
    viewDay({ date }) {
      this.value = date
      this.type = 'day'
    },
    hasEvent(date) {
      const eventDate = this.events.map(e => {
        const d = new Date(e.start)
        let month = `${d.getMonth() + 1}`
        let day = `${d.getDate()}`
        const year = d.getFullYear()

        if (month.length < 2) month = `0${month}`
        if (day.length < 2) day = `0${day}`

        return [year, month, day].join('-')
      })

      return eventDate.includes(date)
    },
    getCurrentTime() {
      return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
    },
    scrollToTime() {
      const time = this.getCurrentTime()
      const first = Math.max(0, time - (time % 30) - 30)
      this.cal.scrollToTime(first)
    },
    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000)
    },
    calcMargin(postTime, prevEvent) {
      const diffMinutes = Math.floor((postTime - prevEvent.start) / 60000)
      if (diffMinutes < 120) {
        return prevEvent.marginLeft + 40
      }

      return 0
    },
  },
}
</script>

<style lang="scss" scoped>
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}

.mini-banner-post {
  border-radius: 4px;
}

.mini-banner-post {
  overflow: hidden;
  width: 75px;
  border: 2px solid #1f979e;
  background: white;
  span {
    padding: 0 4px;
    color: #1f979e;
  }
}
</style>